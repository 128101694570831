.pr-container{
    /* max-width: 1351px; */
    width: 100%;
    margin: 3rem 0px;
    /* padding: 1.2rem; */
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.pr-header{
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
}

.pr-average-rating{
    display: flex;
    justify-content: center;
    align-items: center;
}

.pr-dot{
    transform: translateY(-25%) scale(1.3);
}

.show-all-reviews{
    border-radius: 8px;
    background: white;
    color: black;
    border: 1px solid black;
    cursor: pointer;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    width: fit-content;
    font-weight: 600;
    transition: all 0.2s ease;
}

.show-all-reviews:hover{
    /* background: rgba(0,0,0,0.1); */
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
}

.pr-body{
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
}

.pr-review{
    box-sizing: border-box;
    flex: 0 0 23%;
    padding: 10px;
    padding-bottom: 20px;
    overflow: hidden;
    word-wrap: break-word;

}

.pr-review-header{
    padding-bottom: 3px;
}

.pr-reviewer-name{
    font-size: 1rem;
    font-weight: 600;
    text-transform: capitalize;
}

.pr-reviewe-date{
    font-size: 0.8rem;
    font-weight: 500;
    color: rgba(0,0,0,0.5);
}

.pr-review-body{
    font-size: 0.95rem;
}

.pr-show-less-btn , .pr-show-more-btn{
    font-size: 0.85rem;
    font-weight: 500;
    border-bottom: 1px solid rgba(0,0,0,0.6);
    color: rgba(0,0,0,0.6);
    cursor: pointer;
    transition: all 0.2s ease;
}

.pr-show-less-btn:hover , .pr-show-more-btn:hover{
    color: rgba(0,0,0,0.9);
}

.pr-review-content {
    max-height: 120px; /* Set a maximum height for the content */
    overflow: hidden; /* Hide any overflow */
    transition: max-height 0.3s ease-out; /* Add a transition effect */
  }
  
  .pr-review-content-expanded {
    max-height: none; /* Remove the maximum height when expanded */
  }

@media screen and (max-width: 1024px){
    .pr-review{
        flex: 0 0 47%;
    }
}

@media screen and (max-width: 600px){
    .pr-review{
        flex: 1 0 100%;
        padding: 5px;
    }
    .pr-header{
        font-size: 1rem;
    }
}