
.N-Service-main-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 1450px;
    /* padding-inline: 4px; */
    /* margin-top: 8px; */
}


.N-Main-Service-Item {
    display: flex;
    gap: 15px;
    width: 100%;
    padding: 6px 0px 12px 0px;
    border-bottom: 1px solid #dadada;
    flex-direction: row-reverse;
}
.N-Main-Service-Item:last-child {
    border-bottom: none;
}

.N-Service-Image-Div {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;  
    position: relative;
    /* height: auto; */
}

.N-Service-Image-container {
    width: 95px;
    height: 95px;
    flex-shrink: 0;
    border-radius: 10px;
    overflow: hidden;
    background-color: #f0f0f0;
}

.N-Service-Image-Div img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 10px;
}

.N-Service-Content-Div {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;
    max-width: calc(100% - 110px);
}

.N-Service-Title-Div {
    font-weight: 600;
    width: fit-content;
    max-width: 100%;
    max-width: auto;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    /* padding-bottom: 8px; */
    white-space: nowrap;
    /* border-bottom: 1px dashed #57575650; */
}

.N-Service-Description-Div {
    height: auto;
    width: 100%;
    font-size: 14px;
    color: #383838;
    font-weight: 300;
}

.N-Service-Description-Div ul {
    padding-inline-start: 20px;
}

.N-Service-Description-Div ul li {
    list-style-type: disc;
}

.N-Service-price-book-Div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.N-Service-pricing-Div {
    display: flex;
    align-items: center;
    font-size: 15px;
    width: 100%;
    font-weight: 500;
}
.N-Service-pricing-Div {
    del {
        color: gray;
        text-decoration: none;
        position: relative;
        /* margin-inline: 5px; */
        &:before {
          content: " ";
          display: block;
          width: 95%;
          border-top: 2px solid gray;
          height: 12px;
          position: absolute;
          bottom: 0;
          left: 5%;
          transform: rotate(-10deg);
        }
      }
}



.N-Service-pricing-Div span {
    font-size: 13px;
    font-weight: 400;
}

.N-s-p-d-actual-p {
    color: #000;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.N-Service-call-now-btn {
    min-width:max-content;
    width: 95px;
    padding: 6px 15px;
    border-radius: 15px;
    background-color: #0a0a0a;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
}
.N-Service-call-now-btn-md {
    min-width:max-content;
    width: 70px;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #fff;
    color: #512DC8;
    font-size: 13px;
    cursor: pointer;
    font-weight: 400;

    display: block;
    position: absolute;
    top: 95px;
    transform: translate(-50%, -50%);
    left: 50%;
    box-shadow: 0 2px 3px #00000040;
}
.N-Service-call-now-btn-md:active {
    transform: translate(-40px, -16px);
}
.N-review-shadow-container {
    margin: 0 30px 0 20px;
    box-shadow: 2px 2px 10px 0px #00000030;
    border-radius: 16px;
    padding: 15px;
}

.S-price-cross-a::before {
    content: '';
    height: 1px;
    width: 100%;
    display: block;
    background-color: gray;

}
/* 
@media screen and (max-width:1024px) {
    .N-Service-main-container {
        padding-inline: 20px;
    }    
    .N-category-list-salon-p span:first-child {
        margin-left: 20px;
    }
    .N-category-list-salon-p span:last-child {
        margin-right: 20px;
    }
    .N-salon-service-gender{
        margin-inline: 20px;
    }
}

@media screen and (max-width:768px) {
    .N-Main-Service-Item {
        padding: 8px 0 20px 0;
    }
  
   
    .N-Service-Content-Div {
        gap: 10px;
        width: calc(100% - 105px);
    }
   
    .N-Service-Image-container {
        width: 90px;
        height: 90px;
    }
    .N-Service-Title-Div {
        font-size: 16px;
    }
    .N-Service-Description-Div {
        font-size: 13px;
    }
    .N-Service-pricing-Div {
        font-size: 14px;
    }
    .N-Service-pricing-Div span {
        font-size: 12px;
    }  
  
   
}
 */
