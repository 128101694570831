.navbar-main-parter-page
{
    width: 100%;
    position: fixed; /* Changed to fixed */
    top: 0;
    left: 0;
    /* background-color: rgba(255, 255, 255, 0.9); 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    z-index: 1000;
    padding: 10px 10px;
}

.navbar-main-parter-page.scrolled {
        background-color: #ffffff; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
.container-parter-page
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 10px;
}
.back-icon-parter-page
{
    display: none;
}
.nav-image-container-parter-page
{
    cursor: pointer;
}
.nav-image-container-parter-page img 
{
    height: 30px;
    width: auto;
    /* filter: brightness(0) invert(1); */
}
.profile-icon-parter-page
{
    background-color: #949494cc;
    color: #fff;
    border-radius: 100%;
}
.navback-color-iocn-parter-page
{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.user-nav-icon-parter-page
{

    width: 15px;
}

@media (max-Width: 768px) {
    .navbar-main-parter-page
    {
        background-color: #fff;
        padding: 0 0;
    }
}

@media (Max-Width : 486px) {
    .navbar-main-parter-page
    {
        background-color: #fff;
        padding: 0 0;
    }
    .container-parter-page
    {
        display: flex;
        justify-content: start;
        padding: 15px 18px 10px;
    }
    .nav-image-container-parter-page
    {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .back-icon-parter-page
    {
        display: block;
    
    }
    .profile-icon-parter-page
    {
        background-color: #fff;
    color: #000;
    border-radius: 100%;
    }
    .user-nav-icon-parter-page
{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
    .nav-image-container-parter-page img
    {
        margin-left: 10px;
    }
    .profile-icon-parter-page
    {
        margin-left: auto;
    }
}