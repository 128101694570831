.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; /* Ensure it's above other elements */
    cursor: pointer;
    background-color: #25d366; 
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0.5, 0.5, 0.5, 0.5); 
  }
  
  .whatsapp-button img {
    width: 100%; /* Adjust icon size if needed */
    height: auto;
    fill: #ffffff; /* WhatsApp white color */
  }
  