.N-footer-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px;
    margin-top: 10px;
}

.N-footer-datails {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-grow: 1;
    /* max-width: 320px; */
    max-width: 100%;
}

.N-f-d-logo {
    height: 35px;
    width: 110px;
    text-decoration: none;
    margin-bottom: 10px;
}

.N-f-d-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.N-footer-datails p {
    font-size: 16px;
    color: rgb(90, 90, 90);
}

.N-socia-media-container {
    display: flex;
    gap: 15px;
    justify-content: center;
    width: 100%;
}

.N-socia-media-container a {
    text-decoration: none;
    color: black;
}

.N-socia-media-container a img {
    width: 30px;
    height: 30px;
}

.N-socia-media-container a img:hover {
    transform: scale(1.1);
}

.N-customer-care-div {
    display: flex;
    gap: 5px;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.N-customer-care-div a {
    text-decoration: none;
    color: black;
}

.N-footer-links {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.N-footer-links>div {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.N-footer-links .col-1,
.N-footer-links .col-2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-grow: 1;
    max-width: 50%;
    min-width: 40%;
}

.N-footer-links .col-1 p,
.N-footer-links .col-2 p {
    font-size: 20px;
    color: rgb(0, 0, 0);
    font-weight: 500;
}

.N-footer-links .col-1 ul,
.N-footer-links .col-2 ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.N-footer-links .col-1 ul li,
.N-footer-links .col-2 ul li {
    list-style: none;
}

.N-footer-links .col-1 ul li a,
.N-footer-links .col-2 ul li a {
    text-decoration: none;
    color: rgb(133, 133, 133);
}

.N-footer-copyright {
    font-size: 12px;
    color: rgb(133, 133, 133);
    padding: 10px 20px;
    text-align: center;
}

.N-footer-copyright a {
    text-decoration: underline;
    color: rgb(133, 133, 133);

}

@media screen and (min-width : 576px) {

    .N-footer-links .col-1,
    .N-footer-links .col-2 {
        display: flex;
        flex-direction: column;
        gap: 15px;
        font-size: 14px;
        flex-grow: 1;
        /* max-width: 25%; */
        min-width: auto;
    }

}

@media screen and (min-width : 840px) {
    .N-footer-container {
        flex-direction: row;
        justify-content: space-between;
        gap: 25px;
    }

    .N-footer-datails {
        max-width: min(350px, 40%);
    }

    .N-footer-datails p {
        font-size: 14px;
    }

    .N-customer-care-div {
        font-size: 14px;
    }

    .N-footer-links .col-1,
    .N-footer-links .col-2 {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-grow: 1;
        max-width: 25%;
        min-width: 20%;
    }

    .N-footer-links>div {
        flex-wrap: nowrap;
    }

    .N-footer-links .col-1 ul,
    .N-footer-links .col-2 ul {
        font-size: 13px;
    }

    .N-footer-links .col-1 p,
    .N-footer-links .col-2 p {
        font-size: 18px;
    }
}

@media screen and (min-width: 1024px) {
    .N-footer-datails {
        max-width: min(450px, 40%);
    }
    .N-footer-container {
        gap: 50px;
        padding: 20px 40px;
    }
    .N-footer-links .col-1 ul,
    .N-footer-links .col-2 ul {
        font-size: 14px;
    }
    .N-footer-links .col-1 p,
    .N-footer-links .col-2 p {
        font-size: 20px;
    }
}