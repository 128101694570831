.N-lp-card-listing-container {
    padding: 10px 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(321px, 1fr));
    margin: 0 auto;
    grid-gap: 30px;
    justify-content: center;
}

.N-listpage-heading {
    margin: auto;
    padding-block: 20px 10px;
    /* font-size: 20px; */
    font-weight: 600;
}

.N-listpage-heading h1 {
    font-size: 18px;
    font-weight: 700;
    margin-left: 15px;
}


.N-lp-load-more {
    /* height: 40px; */
    width: max-content;
    border: 1px solid #171717;
    border-radius: 25px;
    /* padding: 5px 0px; */
    display: flex;
    margin: 15px auto;
    justify-content: center;
    align-items: center;

}

.N-lp-load-more button {
    padding: 5px 40px;
}

.N-lp-loader {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #000;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
}

.N-listpage-offer-container {
    margin: auto;
    padding-left: 15px;
}

.N-list-page-container {
    width: 100%;
}

.N-list-page-background-color {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60vh;
    bottom: 0;
    right: 0;
    z-index: -2;
    background: linear-gradient(to bottom, #C4BBE2, #E1DEEB, #FFFFFF);
}

.L-list-page-option-n-filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    overflow: scroll;
    margin-top: 10px;
}

.L-list-page-option-n-filter>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #80808030;
    box-shadow: 0 1px 5px #80808020;
    padding: 7px 12px;
    min-width: max-content;
    font-size: 15px;
    cursor: pointer;

}

.L-list-page-option-n-filter>div:first-child {
    margin-left: 15px;
}

.L-list-page-option-n-filter>div:last-child {
    margin-right: 15px;
}

.L-list-page-option-n-filter>div>img {
    width: 20px;
    height: 20px;
}

@media screen and (min-width: 768px) {
    .L-list-page-option-n-filter{
        margin-top: 40px;
    }
    .L-list-page-option-n-filter>div:first-child {
        margin-left: 40px;
    }   
    .N-lp-card-listing-container {
        padding: 10px 40px;
    }
    .N-listpage-heading h1 {
        margin-left: 40px;
        font-size: 20px;
    }
}

@media screen and (min-width: 1440px) {
    .L-list-page-option-n-filter {
        max-width: 1360px;
        margin: 40px auto 0;
    }
    .L-list-page-option-n-filter>div:first-child {
        margin-left: 0px;
    }
    .L-list-page-option-n-filter>div:last-child {
        margin-right: 0px;
    }
    .N-lp-card-listing-container {
        padding: 10px 0;
        max-width: 1360px;
        
    }
    .N-listpage-heading h1 {
        margin-left: 0px;
        max-width: 1360px;
        margin: auto;

    }
}