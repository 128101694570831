
/* daily updates css */

.N-daily_update_main {
    width: 100%;
    margin-top: 30px;
}

.N-daily_update_salon_details {
    display: flex;
    gap: 15px;
    padding-left:4px ;
}

.N-daily_update_salon_details img {
    height: 50px;
    width: 50px;
    background-color: lightgray;
    border-radius: 50%;
    object-fit: cover;
}

.N-d-u-salon-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.N-d-u-salon-name h2 {
    font-size: 18px;
    font-weight: 600;
}

.N-d-u-salon-name p {
    font-size: 13px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.N-d-u-salon-name p span {
    min-width: max-content;
    display: block;
    flex-grow: 1;
}

.N-daily-update-item-see-more {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    margin-right: 40px;
    background-color: transparent;
}
.N-daily-update-item-see-more img{
    height: 13px;
    width: 13px;
    margin-top: 3px;
    object-fit: contain;
    background-color: transparent;
}

.N-daily_update_container-ss {
    display: flex;
    gap: 15px;
    height: 310px;
    margin-top: 25px;
}

.N-daily_update_card {
    height: 300px;
    width: 256px;
    border-radius: 12px;
    margin-left:4px;
    background: #FFF;
    overflow: hidden;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05), 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
}

.N-daily_update_card:last-child {
    margin-right: 40px;
}

.N-daily_update_card a {
    font-size: 14px;
    font-weight: 500;
    font-family: Inter;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #512DC8;
    border: none;
    outline: none;
    background: none;
    padding-inline: 10px;
}

.N-daily_update_card_img {
    height: 170px;
    width: 100%;
    border-radius: 12px 12px 0 0;
    background-color: lightgray;
}

.N-daily_update_card_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
}

.N-daily_update_card_content {
    padding: 10px;
}

.N-daily_update_card_content p {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    height: 48px;
}

.N-time-ago-daily-update {
    color: #9CA3AF;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding-inline: 10px;
    padding-bottom: 10px;
}

.N-daily-update-popup {
    height: 95%;
    width: max(500px, 50%);
    background: #FFF;
    border-radius: 12px 12px 0 0;
    overflow: scroll;
    padding: 20px;
    position: fixed;
    top: 5%;
    left: 50%;
    outline: none;
    border: none;
    transform: translate(-50%, 0);
    z-index: 100;
    box-shadow: 0px 3.1px 7.751px 0px rgba(0, 0, 0, 0.10), 0px 3.1px 3.1px 0px rgba(0, 0, 0, 0.25);

}

.N-daily-update-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.N-daily-update-popup-header button:first-child {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
}

.N-daily-update-popup-header button:first-child img {
    height: 20px;
    width: 20px;
}

.N-daily-update-popup-header button:first-child span {
    font-size: 18px;
    font-weight: 400;
    color: #000;
}

.N-daily-update-popup-header button:last-child {
    font-size: 2.8rem;
    line-height: 3rem;
    font-weight: 200;
    padding-right: 5px;
    transform: rotate(45deg);

}

.N-du-main-modal-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 3px 3px 0px #00000030;
    border-radius: 10px;
    padding-block: 20px;
}

.N-modal-daily_update_card {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.N-modal-daily_update_card_img {
    width: 100%;
    max-height: 280px;

    background-color: rgb(21, 21, 21);
}

.N-modal-daily_update_card_img img {
    max-height: 280px;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.N-modal-daily_update_card_content {
    padding: 10px 20px;
}

.N-modal-daily_update_card_content p {
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.N-modal-time-ago-daily-update {
    color: #9CA3AF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding-inline: 20px;
    padding-bottom: 10px;
}

.N-modal-daily-update-book-now {
    text-align: center;

    border: 1px solid rgb(71, 71, 71);
    border-radius: 8px;
    color: rgb(71, 71, 71);
    width: 100px;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 20px;
}

.N-modal-daily-update-book-now:hover {
    border-color: #000;
    color: #000;
}

.N-view-more-button-container {
    align-items: center;
    border: 1px solid #171717;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    width: 15%;
    margin: 15px auto 0;
    width: max-content;
    padding: 2px 20px;
}

@media screen and (max-width: 1024px){

    .N-daily-update-item-see-more {
        margin-right: 20px;
    }
    .N-daily_update_card:last-child {
        margin-right: 20px;
    }
}


@media screen and (max-width: 768px) {
    
    .N-daily-update-popup {
        width: 100%;
        height: 100%;
        border-radius: 0;
        top: 0;
    }

    .N-du-main-modal-item {
        box-shadow: none;
    }

    .N-modal-daily_update_card_img {
        border-radius: 10px;
    }

    .N-modal-daily_update_card_img img {
        border-radius: 10px;
    }

    .N-modal-daily_update_card_content {
        padding: 10px 0;
    }

    .N-modal-time-ago-daily-update {
        padding-inline: 0;
    }

    .N-modal-daily-update-book-now {
        margin-left: 0;
    }

}


