/* Marque.css */
.Vendor_marques {
  padding: 2rem 1rem;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1));
  overflow: hidden;
}

.Vendor_faqtitle {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  color: #333;
  background:#333;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 2rem;
}

.Vendor_marquee {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  overflow: hidden;
}

.Vendor_img-marq {
  height: 150px;
  width: auto;
  margin: 0 1rem;
  transition: transform 0.3s ease;
}

.Vendor_img-marq:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 500px) {
  .Vendor_faqtitle {
    font-size: 1.8rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  .Vendor_img-marq {
    height: 150px;
    margin: 0 0.5rem;
  }
}

@media only screen and (min-width: 501px) and (max-width: 1000px) {
  .Vendor_faqtitle {
    font-size: 2.2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .Vendor_img-marq {
    height: 200px;
    margin: 0 0.75rem;
  }
}
