
.ssh-div-handler-main {
  display: none;
}
.ssh-main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ssh-Login-p {
  position: absolute;
  right: 90px;
  top: 20px;
  border: 1px solid black;
  border-radius: 10px;
  width: 60px;
  height: 30px;
  padding: 20px 10px 10px 10px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.ssh-main-div {
  border-radius: 10px;
  width: 520px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: auto;
}
.ssh-cancel {
  width: 520px;
  display: flex;
  justify-content: end;
}
.ssh-cross-icon {
  float: right;
  border-radius: 30px;
  padding: 5px;
  background-color: #ffffff;
  color: black;
  cursor: pointer;
}
.ssh-login-content {
  height: 268;
  width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 5px 5px 5px rgba(0.2, 0.2, 0.2, 0.2);
  background-color: #ffffff;
}
.ssh-loginhead-cancel {
  display: flex;
}
.ssh-login-head-cancel {
  display: flex;
  width: 100%;
  padding: 8px;
}
.ssh-cancelbtn {
  padding-right: 5px;
}
.ssh-login-head {
  height: 28px;
  width: 488px;
  display: flex;
  padding-top: 26px;
  padding-left: 5px;
  padding-bottom: 8px;
  margin-right: 0;
  padding-top: 5px;
  flex-direction: column;
  padding: 0px;
  height: 100%;
  padding-top: 30px;
  padding-left: 15px;
}
.ssh-login-head-p {
  font-size: 20px;
  color: #000000;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0.156686px;
}
.ssh-login-mobile-div {
  width: 488px;
  height: 48px;
  display: flex;
  margin-top: 10px;
}
.ssh-login-country-code {
  width: 96px;
  height: 48px;
  border: 1px solid rgb(209, 206, 206);
  border-radius: 10px 0px 0px 10px;
  display: flex;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  align-items: center;
  font-size: 18px;
  color: #000000;
  font-weight: 500;
}
.ssh-login-mobile-input {
  position: relative;
  width: 100%;
  height: 48px;
  border: 1px solid rgb(209, 206, 206);
  border-left: 0;
  border-radius: 0px 10px 10px 0px;
}
.ssh-login-mobile-inputbox:focus {
  border: 0.5px solid black;
}
.ssh-checkbox-icon:checked {
  background-color: black;
}
input[type="checkbox"] {
  accent-color: black;
}
.ssh-getUpdates {
  color: black;
  font-size: 15px;
  padding-left: 5px;
  font-weight: bold;
  cursor: pointer;
}
.ssh-login-mobile-inputbox {
  width: 100%;
  height: 100%;
  border-radius: 0px 10px 10px 0px;
  border: none;
  padding: 5px;
  box-sizing: border-box;
  font-size: 18px;
  letter-spacing: 5px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  outline: none;
  padding-left: 15px;
  font-weight: 500;
}
.ssh-login-name-input {
  position: relative;
  width: 488px;
  height: 48px;
  border: 1px solid rgb(209, 206, 206);
  border-radius: 10px;
  margin-top: 20px;
  font-size: 15px;
  /* display: none; */
}
.ssh-login-name-inputbox:focus {
  border: 0.5px solid black;
}
.ssh-login-name-inputbox {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: none;
  padding: 15px;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 15px;
  outline: none;
}
.ssh-login-name-inputbox::placeholder {
  color: rgb(113, 109, 109);
}
.ssh-login-mobile-inputbox-label {
  position: absolute;
  left: 10px;
  bottom: 15px;
  transition: all 0.3s ease;
  pointer-events: none;
  color: #999;
}
.ssh-login-mobile-inputbox-label.floating {
  bottom: 30px;
  font-size: 12px;
  color: rgb(113, 109, 109);
}
.ssh-login-mobile-inputbox:focus + .ssh-login-mobile-inputbox-label,
.ssh-login-mobile-inputbox:not(:placeholder-shown) + .ssh-login-mobile-inputbox-label {
  bottom: 40px;
  font-size: 12px;
  background-color: white;
  color: rgb(113, 109, 109);
  padding: 0;
  padding: 0 5px 0 5px;
}
.ssh-login-mobile-inputbox::placeholder {
  letter-spacing: 0;
  color: rgb(113, 109, 109);
}
.ssh-login-verification {
  height: 44px;
  width: 488px;
  display: flex;
  padding: 12px 5px;
  box-sizing: border-box;
}
.ssh-login-verification-p {
  font-size: 15px;
  padding-left: 5px;
}
.ssh-login-whatsapp {
  display: flex;
  height: 48px;
  width: 488px;
  align-items: center;
  padding: 15px 5px;
  gap: 0.4rem;
}
.ssh-checkbox-icon {
  width: 15px;
  height: 15px;
  margin: 0;
  cursor: pointer;
  margin-left: 0.3rem;
}
.ssh-login-whatsapp-p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
  padding-left: 5px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}
.ssh-login-line-whatsapp {
  width: 95%;
}
.ssh-login-line-half {
  width: 47%;
  height: 1px;
  background-color: #ddd;
  margin-top: 10px;
  margin-bottom: 15px;
}
.ssh-login-line-or {
  width: 6%;
  text-align: center;
}
.ssh-div1 {
  display: flex;
}
.ssh-login-button {
  width: 504px;
  height: 48px;
  background-color: #EDEDED;
  border: none;
  margin: 0 15px 15px 15px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 19.23px;
}
.ssh-login-button.valid {
  background-color: #512DC8;
  color: #ffffff;
}
.ssh-whatsapp-div {
  width: 504px;
  border-radius: 12px;
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
}
.ssh-whatsapp-button2 {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  opacity: 100%;
  font-size: 19.23px;
  border: none;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgb(0, 0, 0, 0.6);
}
.ssh-whatsapp-button-p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.216703px;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.6);
}
.ssh-whatsapp-button2 {
  color: white;
}
.ssh-drawer {
  display: none;
}
@media only screen and (max-width: 500px) {
  .ssh-div-handler-main {
      display: flex;
      width: 100%;
      height: 10px;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
  }
  .ssh-div-handler {
      height: 5px;
      width: 30px;
      background-color: rgb(188, 182, 182);
  }
  .ssh-main {
      height: 100% !important;
      padding: 0;
      margin: 0;
      background-color: white;
  }
  .ssh-main-div {
      width: 100%;
      height: auto;
      bottom: 0;
      right: 0;
      padding: 0;
      margin: 0;
  }
  .ssh-cancel {
      display: none;
  }
  .ssh-login-content {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 5px;
      box-sizing: border-box;
  }
  .ssh-login-head {
      width: 100%;
      height: auto;
      padding: 0;
      display: flex;
      justify-content: start;
  }
  .ssh-login-head-p {
      width: auto;
      padding: 14px;
      padding-bottom: 8px;
      padding-left: 14px;
  }
  .ssh-login-mobile-div {
      width: 100%;
      height: auto;
  }
  .ssh-login-country-code {
      width: 30%;
      margin-left: 10px;
      height: 55px;
  }
  .ssh-login-mobile-input {
      width: 100%;
      height: 55px;
      margin-right: 10px;
  }
  .ssh-login-country-code-p {
      font-size: 20px;
  }
  .ssh-login-mobile-inputbox-label {
      font-size: 20px;
      padding: 0;
      margin: 0;
  }
  .ssh-login-line-half {
      padding: 0;
      margin: 0;
  }
  .ssh-div1 {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
  }
  .ssh-login-line-or {
      font-size: 20px;
      color: rgb(169, 167, 167);
  }
  .ssh-login-mobile-inputbox:focus + .ssh-login-mobile-inputbox-label,
  .ssh-login-mobile-inputbox:not(:placeholder-shown) + .ssh-login-mobile-inputbox-label {
      bottom: 48px;
      background-color: white;
      font-size: 13px;
      color: rgba(92, 91, 91, 0.552);
      padding: 0 5px 0 5px;
  }
  .ssh-login-mobile-inputbox {
      padding-left: 20px;
      font-size: 20px;
      height: 53px;
  }
  .ssh-login-name-input {
      width: 100%;
  }
  .ssh-login-verification {
      width: 100%;
      display: flex;
      height: auto;
      align-items: center;
      padding: 0;
      padding-left: 12px;
      padding-top: 12px;
  }
  .ssh-login-verification-p {
      padding-left: 10px;
  }
  .ssh-info-icon {
      user-select: none;
  }
  .ssh-checkbox-icon {
      height: 18px;
      user-select: none;
      width: 18px;
  }
  .ssh-checkbox-label {
      user-select: none;
  }
  .ssh-login-whatsapp {
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      display: flex;
      align-items: center;
      padding-left: 12px;
      margin: 0;
      user-select: none;
  }
  .ssh-login-whatsapp-p {
      padding-left: 5px;
      font-weight: bold;
      background-color: white;
  }
  .ssh-login-button {
      width: 98%;
      height: 50px;
      box-sizing: border-box;
      margin: 2px 0 2px 0;
  }
  .ssh-login-line-whatsapp {
      width: 98%;
      padding: 0;
      margin: 0;
  }
  .ssh-whatsapp-div {
      width: 98%;
  }
}
