.PrivacyPolicy_main {
    border: 1px solid #dedede;
    overflow-y: auto;
    max-height: 710px;
    padding: 30px 80px;
    padding: 30px 80px;
}

.PrivacyPolicy_orderlist {
    padding-left: 2rem;
}

.PrivacyPolicy_orderlist li {
    list-style: decimal;
}

.back_arrow_btn {
    margin: 0 80px;
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 20px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

.back_arrow_btn img {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
}

.back_arrow_btn span {
    font-size: 16px;
}

@media only screen and (max-width:500px) {
    .PrivacyPolicy_main {
        padding: 30px 30px;
    }

    .back_arrow_btn {
        margin-inline: 30px;
    }
}

.PrivacyPolicy_link {
    color: blue !important;
}