.n-salon-register-main-container {
    padding: 20px;
    /* background: linear-gradient(to bottom, #ffffff 0%, #e0e0e0 100%); */
    /* background: linear-gradient(to bottom, #ffffff 0%, #f0f0f0 60%, #d0d0d0 100%); */
    display: flex;
    justify-content: center;
    align-items: stretch; 
    background: #F6F1FF;
  }
  
  .n-salon-register-left-side-card {
    width: 50%;
    background: linear-gradient(to right, #8e2de2, #4a00e0); 
    padding: 20px;
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    justify-content: center;
    border-radius: 15px 0 0 15px;
  }
  
  .n-salon-register-right-side-card {
    width: 50%;
    max-width: 600px;
    padding: 30px;
    border-radius: 0 15px 15px 0;
    background-color: white;
    box-shadow:0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .n-salon-register-logo
{
    width: 80%;
    max-width: 200px;
    margin: 0 auto 20px;
    display: block;
    text-align: center;
    margin-bottom: 20px;
    filter: brightness(0) invert(1);
}
  .n-salon-register-form {
    width: 100%;
  }
  
  .n-salon-register-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .n-salon-register-form-title {
    font-size: 1.8rem;
    font-weight: 600;
  }
  
  .n-salon-register-close-btn {
    background: none;
    border: none;
    color: #333;
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
  }
  
  .n-salon-register-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .n-salon-register-col-1,
  .n-salon-register-col-2 {
    flex: 1;
    min-width: 250px;
  }
  
  .n-salon-register-input-box label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 1rem;
  }
  
  .n-salon-register-input-box input,
  .n-salon-register-input-box select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .n-salon-register-input-box input:focus,
  .n-salon-register-input-box select:focus {
    border-color: #007bff;
  }
  
  .n-salon-register-submit-btn {
    text-align: right;
  }
  
  .n-salon-register-submit-btn button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    background: linear-gradient(to right, #8e2de2, #4a00e0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .n-salon-register-submit-btn button:hover {
    background: linear-gradient(to right, #6a1b9a, #3f51b5);
  }
  
  @media (max-width: 768px) {
    .n-salon-register-main-container {
        background: linear-gradient(to bottom, #ffffff 0%, #f5f5f5 100%);
        flex-direction: column;
        padding: 0; 
    }

    .n-salon-register-left-side-card {
        padding: 20px; 
        text-align: center;
        background: linear-gradient(to right, #8e2de2, #4a00e0); 
        width: 100%;
        border-radius: 0px 0px 0 0;
        height: 50vh; 
        box-shadow: none;
        color: white;
        display: none;
        /* margin-bottom: 20px;  */
    }

    .n-salon-register-right-side-card {
        width: 100%;
        border-radius: 0 0 15px 15px; 
        box-shadow: none;
        padding: 20px;
        background-color: white;
        max-width: 100%;
    }

    .n-salon-register-title-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .n-salon-register-form-title {
        font-size: 1.5rem; 
        /* margin-bottom: 15px;  */
    }

    .n-salon-register-close-btn {
        font-size: 1.3rem; 
        color: #333;
        margin-bottom: 15px;
        display: block;
        background: none;
        border: none;
        cursor: pointer;
    }

    .n-salon-register-row {
        gap: 15px;
        flex-direction: row;
    }

    .n-salon-register-col-1,
    .n-salon-register-col-2 {
        min-width: 100%; 
    }

    .n-salon-register-input-box label {
        font-size: 0.9rem; 
    }

    .n-salon-register-input-box input,
    .n-salon-register-input-box select {
        padding: 12px; 
        font-size: 0.9rem;
    }

    .n-salon-register-submit-btn {
        text-align: start;
        margin-top: 25px;
    }

    .n-salon-register-submit-btn button {
        width: 100%; 
        padding: 12px 0;
        font-size: 1rem;
    }
}
