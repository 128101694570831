
/* .main{
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
} */
.container
{
  width: 304px;
  border-radius: 16px;
  height: 397px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 12px;
  margin:10px;
  box-shadow: 0 5px 20px hsla(52,20%,69%,.22);

}
.subcontainer
{
  padding-top: 10px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 3rem;
}

.textContainer
{
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  
}
.endContainer
{
  padding-top: 10px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}
.offercontainer
{
  width: 160px;
  height: 288px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 50;
  margin:10px
}
.offersubcontainer
{
  width: 100%;
  display: flex ;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  z-index: 50;

}