.swiper {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}
.slider__container{
    padding: 1.5rem 0 0.5rem;
}
.slider__header{
    padding: 1rem 0;
}
.swiper-slide {
    background-position: center;
    background-size: cover;
    /* width: 300px; */
    /* height: 300px; */
    height: auto;
    width: auto;
}
.slider-left-icon{
    border: 1px solid black;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}
.slider-right-icon{
    border-radius: 50%;
    background-color: #000;
    color:white ;
    height: 30px;
    width: 30px;
}
.slider-left-icon svg,
.slider-right-icon svg{
    height: 30px;
    width: 30px;
    padding: 7px;
}

@media screen and (max-width: 899px) {
    .swiper-slide-bw {
        width: 50%;
    }
}
@media screen and (max-width: 768px) {
    .slider-right-icon,
    .slider-left-icon{
        display: none;
    }
    
}
@media screen and (max-width: 486px) {
    .swiper-slide-bw {
        width: 100%;
    }
    .slider__container{
        padding: 0rem 0 0.5rem;
    }
}
