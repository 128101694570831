.css-1oehhmf
{
    top: 0 !important;
}
.form-registration-container{
    padding: 5px 20px 0px 20px;
    background-color: transparent;
    display: flex;
    text-align: left;
}

.registration-card{
    width: 100%;
    padding: 10px 20px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0 2px 0 rgba(90, 97, 105, .11), 0 4px 8px rgba(90, 97, 105, .12), 0 10px 10px rgba(90, 97, 105, .06), 0 7px 70px rgba(90, 97, 105, .1);
}


.form-registration-container form{
    width: 100%;
    padding: 10px 20px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0 2px 0 rgba(90, 97, 105, .11), 0 4px 8px rgba(90, 97, 105, .12), 0 10px 10px rgba(90, 97, 105, .06), 0 7px 70px rgba(90, 97, 105, .1);
}

.form-registration-container .row{
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-bottom: 20px;
}

.form-registration-container .col-1{
    /* padding-left: 15px; */
    width: 100%;
}

.form-registration-container .col-2{
    /* padding-right: 15px; */
    width: 100%;
}

.form-registration-container .submit-btn button{
    width: 100px;
    height: 2.3rem;
    /* background-color: rgb(129, 129, 249); */
    background-color: rgb(59 177 251);
    color: white;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    font-size: 1.1rem;
}

.form-registration-container label{
    display: block;
    line-height: 2rem;
    font-size: 1.05rem;
    margin-bottom: 3px;
}

.form-registration-container input , textarea , select{
    width: 100%;
    box-sizing: border-box;
    font-size: 0.93rem;
    padding-left: 5px;
    outline: none;
    border: 1px solid rgb(64 64 64 / 38%);
    border-radius: 5px;
}

.form-registration-container input , select{
    line-height: 1.9rem;
}

.form-registration-container select{
    padding: 5px;
}

.form-registration-container textarea{
    box-sizing: border-box;
    width: 100%;
}

.form-registration-container input:focus , select:focus , textarea:focus{
    outline: rgb(129, 129, 249) solid 2px;
}

.form-registration-container .inp-timings{
    display: flex;
    flex-direction: column;
}

.form-registration-container input[type="file"]{
    border: none;
    outline: none;
    line-height: 1.5rem;
}

.form-registration-container .timing-label{
    margin-left: 15px;
}

.form-registration-container .submit-btn{
    padding-top: 15px;
}

.form-registration-container .form-title{
    font-size: 1.7rem;
    font-weight: 500;
}
.submit-primary{
    border: 1px solid rgb(129, 129, 249) !important;
    background-color: white !important;
    color: rgb(129, 129, 249) !important;
}

@media screen and (max-width: 920px) {
    .form-registration-container form{
        width: 90%;
        padding: 10px 10px;
    }
    .form-registration-container .row{
        gap: 40px;
    }
}

@media only screen and (max-width: 768px) {
    .form-registration-container form{
        width: 95%;
    }
    .form-registration-container .row{
        flex-direction: column;
        gap: 15px;
    }
    .form-registration-container .col-1{
        padding-left: 5px;
        padding-right: 5px;
    }
    .form-registration-container .col-2{
        padding-right: 5px;
        padding-left: 5px;
    }
    .form-registration-container .timing-label{
        margin-left: 2px;
    }
    .form-registration-container .timings{
        gap: 0;
    }
}
