.my-container {
    width: 100%;
    margin: auto;
    padding: 0 15px;
}

/* For tablets and small desktops */
@media (min-width: 768px) {
    .my-container {
        max-width: 95vw;
    }
}

/* For medium desktops */
@media (min-width: 992px) {
    .my-container {
        max-width: 95vw;
    }
}

/* For large desktops and TVs */
@media (min-width: 1200px) {
    .my-container {
        max-width: 95vw;
    }
}


.grid-container {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 4fr 2fr 2fr;
    grid-gap: 10px;
    height: 400px;
    margin-bottom: 1rem;
    max-height: 400px;
    min-width: 1000px;
}

.vertical-div {
    grid-column: 1;
    grid-row: 1 / span 3;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    max-width: 630px;
    max-height: 400px;
    min-height: 256px;
    min-width: 400px;
    height: 24.625rem;
}

.div-3,
.div-1,
.div-2,
.div-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    height: 12rem;
}

/* 2 3 1 4 */

.div-3 {
    grid-column: 2 / span 1;
    grid-row: 2 / span 2;
}

.div-4 {
    grid-column: 3 / span 1;
    grid-row: 2 / span 2;
}

.spa-profile__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* margin: 5% auto auto auto !important; */
    margin-top: 20px;
    min-width: 320px;
    width: 100%;
    max-width: 1450px;
}

.spa-description__container {
    display: grid;
    width: 95%;
    margin: 0px 5%;
}

.grid-image {
    height: 100%;
    width: 100%;
    max-width: 245px;
    max-height: 172px;

    object-fit: cover;

}

.show-all-photos {
    position: absolute;
    top: -4.5rem;
    right: 0.3rem;
    font-size: large;
    font-weight: 700;

}

.show-all-photos button {
    border-radius: 8px;
    background: white;
    color: black;
    border: 1px solid black;
    cursor: pointer;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .3rem
}


.spa-information__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 0.6rem;
}

.spa-information-title,
.spa-information-subtitle {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.spa-information-title h1 {
    font-size: 24px;
    font-weight: 600;
}

.spa-information-like-share {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-weight: 500;
    font-size: 22px;
    line-height: 20px;
}

.spa-like,
.spa-share {
    display: flex;
    cursor: pointer;
    align-items: center;
    column-gap: .3rem;
    row-gap: 0;
    min-width: 4rem;
}

.spa-information-ratings-reviews,
.spa-information-phone-price {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-weight: 400;
    font-size: 1.25rem;
}

.spa-information-ratings-reviews {
    cursor: pointer;
}

.spa-ratings img {
    height: 1.25rem;
    width: 1.25rem;
}

.spa-ratings,
.spa-reviews,
.spa-phone,
.spa-price {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: .3rem;
    row-gap: 0;
}

.spa-information-tags-offers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .5rem;
}

.spa-information-tags {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    font-family: 'Kanit';
    align-items: center;

    justify-content: center;
}

.spa-information-tags li {
    background: #EEEEEE;
    border-radius: 23px;
    cursor: pointer;
    padding: .2rem 0.7rem;
    transition: var(--transition);
}

.spa-information-tags li:active {
    transform: scale(.9);
}

.spa__image__container {
    width: 100%;
    height: 100%;
}

.spa-information-offer {
    text-align: center;
    color: white;
    background: #512DC8;
    padding: .5rem 1rem;
    border-radius: 25px;
}

.spa-information-contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.3125rem 0;
    border-top: 2px solid #E5E7EB;
    border-bottom: 2px solid #E5E7EB;
}

.spa-information-bookings {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.spa-information-bookings li a ,
.spa-information-bookings li button
{
    background: black;
    color: white;
    border-radius: 12px;
    cursor: pointer;
    padding: .5rem 1rem;
    transition: var(--transition);
}

.spa-phone {
    font-family: 'Kanit';
}

.spa-information-bookings li:active {
    transform: scale(.9);
}

/* Spa Profile CSS for smaller screens */

.spa-profile-mini__container {
    display: grid;
    grid-template-columns: 1fr !important;
    /* width: 94%; */
    /* margin: 0 3%; */
}

/* category list */
.category-list-outer-container {
    position: sticky;
    width: 100%;
    top: 0px;
    background-color: white;
    padding-bottom: 3px;
    border-bottom: 1px solid #000;
}

.category-list-spa-p {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0 25px;
}

.category-list-spa-p span {
    border: 1px solid #493E6C;
    border-radius: 10px;
    padding: 5px 15px;
    width: max-content;
    min-width: max-content;
    text-align: center;
}

@media screen and (min-width: 900px) {
    .service-profile-div {
        width: 65%;
    }
}

@media screen and (max-width: 900px) and (min-width: 520px) {
    .service-profile-div {
        padding-inline: 1rem;
    }

}

@media screen and (max-width: 52px) {
    .service-profile-div {
        padding-inline: 10px;
    }
}

@media screen and (max-width:545px) {
    /* .spa-profile-mini__container {
        transform: scale(.9);
        width: 90%
    } */

    .spa-information-bookings {
        width: 100%;
    }

    .category-list-spa-p {
        flex-wrap: nowrap;
        overflow: scroll;
        gap: 7px;
        padding: 7px 0 15px;
    }

    .category-list-spa-p span {
        padding: 5px 10px;
        width: max-content;
        font-size: 13px;
        border-radius: 7px;
    }

    .spa_profile__left_container,
    .spa_profile__right_container {
        width: 90%;
        margin: 2rem 5%;
        max-height: 70vh;
        overflow: scroll;
    }
}

@media screen and (max-width:483px) {
    .spa-profile-card-img {
        height: 14rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }

    .spa-information-ratings-reviews {
        font-size: 1rem !important;
        ;
    }
}



.spa-information-mini-title {
    display: flex;
    flex-direction: column;
    margin: 2rem 1rem;
    gap: 1rem;
}
.spa-information-mini-title > a{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.spa-information-mini-title h2 {
    font-size: 24px;
    font-weight: 600;
    /*     line-height: 0px; */
}

@media screen and (max-width:475px) {
    .spa-information-bookings li {
        font-size: .8rem;
        display: flex;
    }

    .spa-information-bookings li a ,
    .spa-information-bookings li button{
        padding: 0.4rem 0.7rem;
    }
}

.spa_main_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 5% auto auto auto !important;  */
    min-width: 320px;
    width: 100%;
    /* max-width: 1800px; */
    margin-left: auto;
    margin-right: auto;
}

/* Spa services and offers container css starts  */
.spa_offers-services__container {
    display: flex;
    /* margin-top: 2rem; */
    min-width: 320px;
    max-width: 1800px;
    width: 100%;
}

.servos__header h2 {
    /* padding-bottom: 1rem; */
    font-size: 1.3rem;
    font-weight: 500;
}

.servos__list {
    width: 100%;
    margin-bottom: 2rem;
}

.servos__list ul li {
    padding: 0.5rem 0;
    border-bottom: 2px solid #D2D2D2;
}

/* New CSS */
.service_name p {
    font-weight: 400 !important;
    line-height: normal;
    margin: 10px 0px;
}

@media screen and (max-width: 475px) {
    .service_name p {
        font-size: 1rem !important;
    }
}

/* Spa services and offers container css ends  */


/* Services css starts  */

.services_list__container {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    line-height: 2.5rem;
}

.service_list__left_container {
    margin-right: 2rem;
}

.service_description,
.offers_description {
    display: flex;
    align-items: center;
    font-size: smaller;
}

.service_description p,
.offers_description p {
    color: #383838;
    line-height: normal;
    align-items: center;
}

.service_price,
.services__button {
    width: 100%;
}

.service_price p,
.offer_price p {
    color: #383838;
}

.service_price p span,
.offers_price p span {
    color: #000;
    font-size: smaller;
}

.spa_info_btn {
    background-color: black;
    width: 100px;
    height: 40px;
    border-radius: 30px;
    color: white;
    text-align: center;
    padding: 7px;
}

.services__button a {

    display: block;
    margin: 0;
    padding: 0px;
    font-size: 14px;
    border: none;
    border-radius: 30px;
    background: black;
    color: white;
    width: 100px;
    height: 38px;
    text-align: center;


}

/* Services css ends  */

/* Spa Offers css starts  */
.spa_offers__container {
    /* width: 35%; */
    height: fit-content;
    min-width: 300px;
    max-width: 650px;
    /* margin: 0 2%; */
    margin-top: 30px;
    padding: 1.5rem;
    border: 2px solid #d3d3d3;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
    min-height: 12rem;
}

.offers_list__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    width: 100%;
}

.offers_list__bottom_container {
    display: flex;
    align-items: center;
}

.offers_price {
    width: 50%;
}

.offers__button {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.offers__button a {
    /* display: unset;
    padding: 0 1.5rem;
    text-align: center;
    background-color: #000;
    border-radius: 15px;
    color: #FFF; */


    padding: 1px;
    font-size: 12px;
    text-align: center;
    border: none;
    border-radius: 35px;
    background: black;
    color: white;
    width: 90px;
    height: 40px;







}

.offers_list__container {
    line-height: 2.5rem;
}

/* Spa Offers css ends  */

.spa_profile__left_container {
    width: 46%;
    margin: 0 5% 0 10%;

    max-height: 55rem;
    overflow: scroll;
    padding-right: 5px;

}


.spa_profile__left_container::-webkit-scrollbar {
    width: 5px;
    height: 0px;
    display: block;
}

.spa_profile__left_container::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* height: 5px; */
    width: 5px;

}

.spa_profile__left_container::-webkit-scrollbar-thumb {
    background: #bebebe;
    border-radius: 2px;
}


.spa_profile__right_container {
    width: 35%;
}

.spa_facility__container {
    margin: 1rem 0;
    padding: 2rem;
}

.spa_facility_list ul li {
    display: flex;
}

.spa_facility_icon {
    display: flex;
    height: 4rem;
}

/* .spa_facility_icon img {
    height: 2rem;
    margin: 1rem 1rem 1rem 0;
} */

@media screen and (max-width: 450px) {
    /* .spa_facility_icon {
        height: 2.5rem !important;
    } */
}

.spa_facility__name {
    display: flex;
    align-items: center;
}

.spa_about_us__container,
.spa_rooms_slider__container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 2rem 5px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(220, 220, 220, 0.1);
    max-width: 1450px;
}



.spa_about_us__description p {
    padding: 0.5rem 0;
}

.spa_about_us__description button {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
}

.spa_show_more__button {
    color: black;
    text-decoration: underline;
    font-weight: bold;
}


.hr_line {
    width: 80%;
    margin: 0 10%;
    color: #383838;
}


@media screen and (max-width: 1200px) {
    .spa_profile__right_container {
        width: 42%;
    }

    .spa_profile__left_container {
        margin: 0 2% 0 5%;
        max-height: 55rem;
        overflow: scroll;
    }
}

@media screen and (max-width: 1030px) {
    .spa_offers-services__container {
        display: block;
    }

    .spa_profile__left_container,
    .spa_profile__right_container {
        width: 90%;
        margin: 2rem 5%;
        max-height: 55rem;
        overflow: scroll;
    }

    .spa_offers__container {
        border: none;
        box-shadow: none;
        padding: 0;
    }

    .spa_facility__container {
        margin: 1rem auto;
        padding: 2rem;
        width: fit-content;
    }
}

@media screen and (max-width: 600px) {
    .spa-information-bookings {
        justify-content: space-between;
        gap: 1rem;
    }

    .review-model-box {
        width: 95% !important;
    }
}

@media screen and (max-width: 390px) {
    .spa-information-bookings {
        justify-content: space-between;
        gap: 2px;
    }

    .spa-information-bookings>li {
        font-size: 0.8rem;
    }

    .spa-information-bookings li a ,
    .spa-information-bookings li button{
        padding: 0.4rem 0.5rem;
    }
}

@media screen and (max-width: 899px) {
    .spa_facility__container {
        display: none;
    }

}

@media screen and (max-width:900px) {
    .right_hidden {
        display: none;
    }


}


/* New CSS */

/* .profile-page-facility-icon{
    height: 3rem !important;
} */

/* Room Images */

.sp-room-main-container {
    height: 340px;
    width: 100%;
    max-width: 1450px;
    margin-top: 20px;
}

.sp-room-main-heading {
    height: 60px;
    width: 100%;
    font-weight: 600;
    font-size: 1.3rem;
}

.sp-room-item-container {
    height: 280px;
    width: 100%;
    display: flex;
    gap: 22px;
    overflow-x: scroll;
    flex-direction: row;
}

.sp-room-item-container::-webkit-scrollbar {
    width: 0px;
    height: 5px;
    display: block;
}

.sp-room-item-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    height: 5px;
}

.sp-room-item-container::-webkit-scrollbar-thumb {
    background: #bebebe;
}

.sp-room-item-div {
    height: 260px;
    width: 300px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.sp-room-img {
    height: 220px;
    width: 300px;
    background-color: #bebebe;
    border-radius: 8px;

}

.sp-room-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.sp-room-item-details {
    height: 40px;
    width: 100%;
    font-weight: 500;
    font-size: 1.1rem;
    padding: 0.5rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media screen and (max-width:520px) {

    .sp-room-main-container {
        height: 300px;
        width: 100%;
        max-width: 90vw;
    }

    .sp-room-item-container {
        height: 240px;
        width: 100%;
        gap: 18px;
    }

    .sp-room-item-div {
        height: 230px;
        width: 270px;
    }

    .sp-room-img {
        height: 190px;
        width: 270px;
    }



}

@media screen and (max-width: 520px) {

    .spa-information-mini-title {
        margin-top: 1.5rem;
        margin-left: 0;
        margin-right: 0;
        gap: 0.7rem;
    }

    .spa-information-mini-title a{
        gap: 0.7rem;
    }

    .spa-information-mini-title {
        margin: 2rem 0;
        padding-inline: 5px;
    }
}

@media screen and (max-width: 520px) {
    .servos__header h2 {
        font-size: 18px;
    }

    .spa_about_us__description p {
        font-size: 14px;
    }

}

.service-c-div::-webkit-scrollbar {
    width: 5px;
    height: 0px;
    display: block;
}

.service-c-div::-webkit-scrollbar-track {
    background: #f1f1f1;
    width: 5px;
    border-radius: 4px;
}

.service-c-div::-webkit-scrollbar-thumb {
    background: #bebebe;
    border-radius: 4px;
}



/* daily updates css */

.daily_update_main {
    width: 100%;
    margin-top: 25px;
}

.daily_update_spa_details {
    display: flex;
    gap: 15px;
    margin-left: 10px;
}

.daily_update_spa_details img {
    height: 50px;
    width: 50px;
    background-color: lightgray;
    border-radius: 50%;
    object-fit: cover;
}

.d-u-spa-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.d-u-spa-name h2 {
    font-size: 18px;
    font-weight: 500;
}

.d-u-spa-name p {
    font-size: 13px;
    font-weight: 300;
}

.daily_update_container-ss {
    display: flex;
    gap: 15px;
    height: 325px;
    margin-top: 25px;
}

.daily_update_card {
    height: 290px;
    width: 240px;
    border-radius: 12px;
    margin-right: 20px;
    background: #FFF;
    overflow: hidden;
    box-shadow: 0px 3.1px 7.751px 0px rgba(0, 0, 0, 0.10), 0px 3.1px 3.1px 0px rgba(0, 0, 0, 0.25);
}

.daily_update_card:first-child {
    margin-left: 10px;
}

.daily_update_card a {
    font-size: 12px;
    font-weight: 500;
    font-family: Inter;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #512DC8;
    border: none;
    outline: none;
    background: none;
    padding-inline: 10px;
}

.daily_update_card_img {
    height: 160px;
    width: 100%;
    border-radius: 12px 12px 0 0;
    background-color: lightgray;
}
.daily_update_card_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
}

.daily_update_card_content {
    padding: 10px;
}

.daily_update_card_content p {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    height: 48px;
}

.time-ago-daily-update {
    color: #9CA3AF;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding-inline: 10px;
    padding-bottom: 10px;
}


.daily-update-popup {
    height: 95%;
    width: max(500px, 50%);
    background: #FFF;
    border-radius: 12px 12px 0 0;
    overflow: scroll;
    padding: 20px;
    position: fixed;
    top: 5%;
    left: 50%;
    outline: none;
    border: none;
    transform: translate(-50%, 0);
    z-index: 100;
    box-shadow: 0px 3.1px 7.751px 0px rgba(0, 0, 0, 0.10), 0px 3.1px 3.1px 0px rgba(0, 0, 0, 0.25);

}

.daily-update-popup-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.daily-update-popup-header button:first-child{
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
}
.daily-update-popup-header button:first-child img{
    height: 20px;
    width: 20px;
}
.daily-update-popup-header button:first-child span{
    font-size: 18px;
    font-weight: 400;
    color: #000;
}

.daily-update-popup-header button:last-child{
    font-size: 2.8rem;
    line-height: 3rem;
    font-weight: 200;
    padding-right: 5px;
    transform: rotate(45deg);

}

.du-main-modal-item{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 3px 3px 0px #00000030;
    border-radius: 10px;
    padding-block: 20px;
}
.modal-daily_update_card{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;   
}
.modal-daily_update_card_img{
    width: 100%;
    max-height: 280px;

    background-color: rgb(21, 21, 21);
}
.modal-daily_update_card_img img{
    max-height: 280px;
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.modal-daily_update_card_content{
    padding: 10px 20px;
}
.modal-daily_update_card_content p{
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}
.modal-time-ago-daily-update{
    color: #9CA3AF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding-inline: 20px;
    padding-bottom: 10px;
}
.modal-daily-update-book-now{
    text-align: center;

    border: 1px solid rgb(71, 71, 71);
    border-radius: 8px;
    color: rgb(71, 71, 71);
    width: 100px;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 20px;
}
.modal-daily-update-book-now:hover{
    border-color: #000;
    color: #000;
}

@media screen and (max-width:768px) {
    .daily-update-popup {
        width: 100%;
        height: 100%;
        border-radius: 0;
        top: 0;
    }
    .du-main-modal-item{
        box-shadow: none;
    }
    .modal-daily_update_card_img{
        border-radius: 10px;
    }
    .modal-daily_update_card_img img{
        border-radius: 10px;
    }
    .modal-daily_update_card_content{
        padding: 10px 0;
    }
    .modal-time-ago-daily-update{
        padding-inline: 0;
    }
    .modal-daily-update-book-now{
        margin-left: 0;
    }
}


.view-more-button-container{
    align-items: center;
    border: 1px solid #171717;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    width: 15%;
    min-width: max-content;
    padding: 2px 15px;    
}
