.N-spa-p-main-info-icon {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    color: white;
  }
  
  .N-spa-p-main-info-icon img {
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 3px 3px #00000030);
  }

  
.N-spa-p-more-photo-btn {
  position: absolute;
  right: 10px;
  bottom: 15px;
  z-index: 11;
  color: white;
}

.N-spa-p-more-photo-btn button {
  background-color: #00000030;
  color: white;
  border: 1px solid #00000040;
  display: flex;
  gap: 5px;
  padding: 6px 10px;
  border-radius: 7px;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
}

.N-spa-p-more-photo-btn img {
  width: 20px;
  height: 20px;
  z-index: 1;
}


.NProfileSwiper .swiper-button-prev ,
.NProfileSwiper .swiper-button-next {
  color: white;
  background-color: #f0f0f030;
  /* border: 1px solid #00000040; */
  /* padding: 5px; */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  /* overflow: hidden; */
}
.NProfileSwiper .swiper-button-prev::after,
.NProfileSwiper .swiper-button-next::after {
  font-size: 12px;
  /* width: 100%; */
  margin: auto;
  font-weight: 600;
}
.NProfileSwiper .swiper-scrollbar {
  display: none;
}

.BSM-N-container ul {
  padding-inline-start: 20px !important;
}

.BSM-N-container ul li {
  list-style-type: disc !important;
}

@keyframes progressBar {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.progress-bar-animation {
  animation: progressBar 4s linear forwards;
}
