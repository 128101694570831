@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: 'inter';
  /* -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

a {
  color: #000;
}


:root {
  --transition: .5s;
}

.main__container section {
  background-color: #000;
}

button {
  text-decoration: none;
  outline: none;
  border: none;
  transition: var(--transition);
}

button:active {
  transform: scale(0.9);
}

body {
  min-height: 100vh;
  width: auto;
  background-color: #FFFFFF;
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;
}
*::-webkit-scrollbar {
  display: none;
}

.slider__outer-container .swiper-button-prev, .slider__outer-container .swiper-button-next {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}