
.report-btn{
    margin: 20px auto;
    width: 148px;
}
/* .select-city{
    background-color: white;
} */

.report-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:50vw;
    margin: 14px;
    margin-left: auto;
    margin-right: auto;
    
  }
  
  .report-heading {
    text-align: center;
   
  }
  
  .report-form {
    display: flex;
    flex-direction: column;
    align-items:center;
    gap: 3vh;
    padding-top: 1rem;
    max-width: 100%;
    /* padding-right: 8rem;
    padding-left: 8rem; */
    width:100%;
    
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    /* margin-left: 5rem; */
    /* margin-right: 5rem; */
  }
.report-form select{
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    /* border-radius: 6px; 
    color: #9B9B9B; */
}
  
  /* .select-city,
  .select-salon {
    height: 5vh;
  } */



  
  .experience-textarea {
    height: 35vh !important;
    background: #FFFFFF;
    border: 1.29335px solid #9B9B9B;
    border-radius: 10.3468px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    /* border: 1px solid green; */
    outline: none;
    padding: 0 15px;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 6px;
    color: #7c7c7c;
    position: relative;
    background-color: white !important;
    background-origin: content-box;
  }
  .report-textarea{
    width: 100%;
    position: relative;
  }
  .report-textarea label{
    position: absolute;
    font-size: 1.2rem;
    font-weight: 500;
    color: #7c7c7c;
    left: 15px;
    top: 13px;
    transition: all 0.3s ease;
    background-color: #fff;
  }
  .report-textarea label{
    top: -15px;
    left: 15px;
    color: #7c7c7c;
    font-size: 1rem;
    font-weight: 500;
    background-color: #fff;
    padding: 0 5px;
    border-radius: 5px;
  }
.report-textarea textarea{
  padding-top: 5px;
  font-weight: 400;
  color: black;
}
  .experience-textarea{
    overflow: hidden;
    outline:none;
  }
  
  .report-btn {
    background-color: #6B45BC;
    color: white;
    height: 38px;
    width: 148px;
    border-radius: 10px;
  }
  
  @media (max-width: 779px) {
    
    .report-form{
        /* padding-left: 1rem;
        padding-right: 1rem; */
        /* margin-left:0;
        margin-right:0; */
        width:100%
    }
    .report-container{
      width:100%;
    }
  }
  @media screen and (max-width: 350px){
    .report-btn{
      background: linear-gradient(90deg, #9E70FF 0%, #9E70FF 0.01%, #512DC8 100%);

    }
  }


  /* Group 1000002832 */

/* position: absolute;
width: 787px;
height: 469px;
left: 30px;
top: 16px; */



/* Report Salon */

/* position: absolute;
width: 223px;
height: 44px;
left: 312px;
top: 16px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 44px; */
/* identical to box height */
/* display: flex;
align-items: center;
letter-spacing: 0.323338px; */

/* color: #000000; */



/* Button */

/* Auto layout */
/* display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16.1669px 20.6936px;
gap: 12.93px;

position: absolute;
width: 148px;
height: 38px;
left: 350px;
top: 447px;

background: #512DC8;
border-radius: 10.3468px; */


/* BUTTON */

/* width: 58px;
height: 18px;

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 15.6826px;
line-height: 18px;
display: flex;
align-items: center;
text-align: center;
text-transform: uppercase; */

/* Light/Gray-0 */
/* color: #FFFFFF; */


/* Inside auto layout */
/* flex: none;
order: 0;
flex-grow: 0; */


/* Group 1000002830 */

/* position: absolute;
width: 787px;
height: 269px;
left: 30px;
top: 147px; */



/* Rectangle 8 */

/* box-sizing: border-box;

position: absolute;
left: 3.54%;
right: 3.54%;
top: 26.73%;
bottom: 24.36%;

background: #FFFFFF;
border: 1.29335px solid rgba(0, 0, 0, 0.06);
border-radius: 10.3468px; */


/* Write us about your experience... */
/* 
position: absolute;
left: 5.19%;
right: 24.44%;
top: 29.64%;
bottom: 64.18%;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 21.96px;
line-height: 34px;
/* or 157% */
/* display: flex;
align-items: center;
letter-spacing: 0.03em;

color: rgba(0, 0, 0, 0.33); */ 



/* icons/regular/chevron-down-s */

/* position: absolute;
left: 91.03%;
right: 4.46%;
top: 15.09%;
bottom: 77.96%; */



/* Icon */
/* 
position: absolute;
left: 20.83%;
right: 20.83%;
top: 37.5%;
bottom: 29.17%;

background: rgba(0, 0, 0, 0.39); */


/* Group 1000002831 */

/* position: absolute;
width: 787px;
height: 50px;
left: 30px;
top: 77px; */



/* Rectangle 7 */

/* box-sizing: border-box;

position: absolute;
left: 3.54%;
right: 3.54%;
top: 14%;
bottom: 76.91%;

background: #FFFFFF;
border: 1.29335px solid rgba(0, 0, 0, 0.06);
border-radius: 10.3468px; */


/* Select Salon */

/* position: absolute;
left: 5.19%;
right: 24.44%;
top: 15.45%;
bottom: 78.36%;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 21.96px;
line-height: 34px;
/* or 157% */
/* display: flex;
align-items: center;
letter-spacing: 0.03em;

color: rgba(0, 0, 0, 0.33); */ 

