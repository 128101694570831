.homepage-main-title{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px 10px;
    font-size: 1.5rem;
    font-weight: 600;
}
.homepage-main-title span{
    width: 80px;
    height: 80px;
}
.homepage-main-title span img{
    height: 100%;
    width: 100%;
}
.homepage-disc{
    text-align: center;
    font-size: 16px;
    padding: 20px 10px;
    max-width: 80%;
    margin: auto;
}
.main-area-links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    row-gap: 1rem;
    padding: 0 30px;
    width: 80%;
    margin: 1.5rem auto;
    /* width: fit-content; */
    column-gap: 1rem;
}
.main-area-links div{
    width: 100%;
    border: 1px solid rgba(0,0,0,0.3);
    height: 60px;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    align-items: center;

}
.area-group-item Img{
    width: 20px;
    height: 20px;
}
.show-more-button-area{
  display: flex;
  justify-content: center;
  width: 100%;
  text-decoration: underline;
  padding: 20px;
  font-weight:400;
}

@media screen and (max-width: 768px) {
    .main-area-links {
        grid-template-columns: repeat(2, 1fr);
        width: 90%;
    }
    .homepage-disc{
        text-align: center;
        font-size: 16px;
        padding: 15px 30px;
        max-width: 100%;
        margin: auto;
    }

}
@media screen and (max-width: 540px) {
    .main-area-links {
        grid-template-columns: repeat(1, 1fr);
        width: 90%;
    }
    .homepage-main-title{
        font-size: 1.2rem;
        padding: 10px 40px 10px;
    }
    .homepage-main-title span{
        width: 60px;
        height: 60px;
    }
    .homepage-disc{
        font-size: 14px;
    }
    .show-more-button-area{
        padding: 10px;
    }

}