.parter-with-us-hero-container {
    background-image: url('https://img.freepik.com/premium-photo/hairdresser-set-with-various-accessories-pink-background-copy-space_609048-2953.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
    font-weight: 700;
    color: white;
    text-align: center; /* Center text */
    padding: 50px 20px;
    overflow: hidden;
}

.parter-with-us-hero-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); 
    z-index: 1;
}


.hero-content {
    position: relative;
    z-index: 2;
    max-width: 800px;
    padding: 20px;
    text-align: center;
}

.hero-title {
    font-size: 3rem; /* Larger font size for the main title */
    line-height: 1.2;
    margin-bottom: 20px;
}

.hero-subtitle {
    font-size: 1.8rem; /* Smaller subtitle */
    margin-bottom: 30px; /* Space below the subtitle */
    line-height: 1.4;
}

.hero-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #8a2be2; /* Complementary button color */
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 30px; /* Rounded button */
    text-decoration: none;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.hero-button:hover {
    background-color: #6a1bb2; /* Darker shade on hover */
    transform: translateY(-3px); /* Slight lift effect */
}

@media (max-width: 768px) {
    .parter-with-us-hero-container
    {
        height: 70vh;
    }
    .hero-title {
        font-size: 2rem;
    }
    .hero-subtitle {
        font-size: 1.5rem;
    }
    .hero-button {
        font-size: 1rem;
        padding: 12px 25px;
    }
}

@media (max-width: 480px) {
    .parter-with-us-hero-container
    {
        height: 65vh;
    }
    .hero-title {
        font-size: 1.5rem;
    }
    .hero-subtitle {
        font-size: 1.2rem;
    }
    .hero-button {
        font-size: 0.9rem;
        padding: 10px 20px;
    }
    .hero-content
    {
        margin-top: 45px;
    }
}
