.contactus-main-hero-container {
    display: flex;
    justify-content: center;
    padding: 40px 20px;
    background-color: #f7f7f7;
  }
  
  .form-main-container {
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    max-width: 700px;
    background: #ffffff;
    padding: 40px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .contactus-title-main h3 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .contactus-form-main-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .contactus-form-main-col {
    flex: 1;
    min-width: 280px;
    padding: 0 10px;
  }
  
  .contactus-form-main-col label {
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    color: #555555;
  }
  
  .contactus-form-main-col input,
  .contactus-form-main-col textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #dddddd;
    border-radius: 6px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .contactus-form-main-col input:focus,
  .contactus-form-main-col textarea:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
  }
  
  .contactus-form-main-col textarea {
    min-height: 120px;
    resize: vertical;
  }
  
  .checkbox-col {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .checkbox-col input[type="checkbox"] {
    height: 18px;
    width: 18px;
  }
  
  .contactus-form-main-row button {
    width: 100%;
    padding: 14px;
    color: #ffffff;
    background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
    border: none;
    border-radius: 6px;
    font-size: 18px;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s;
  }
  
  .contactus-form-main-row button:hover {
    background: linear-gradient(135deg, #2575fc 0%, #6a11cb 100%);
    transform: translateY(-3px);
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .contactus-main-hero-container
    {
        flex-direction: column-reverse;
    }
    .contactus-title-main h3 {
      font-size: 24px;
    }
  
    .contactus-form-main-row {
      gap: 15px;
    }
  
    .contactus-form-main-col input,
    .contactus-form-main-col textarea {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .form-main-container {
      padding: 20px;
    }
  
    .contactus-title-main h3 {
      font-size: 20px;
    }
  
    .contactus-form-main-row {
      gap: 10px;
    }
  
    .contactus-form-main-col input,
    .contactus-form-main-col textarea {
      font-size: 12px;
    }
  
    .contactus-form-main-row button {
      font-size: 14px;
      padding: 12px;
    }
  }
  