
/* About Us */

.N-about-us-section {
    background-color: transparent;
    margin: 2.5rem auto;
    padding: 1.2rem 0.8rem;
    width: 92%;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.N-about-us-section h1 {
    font-size: 24px;
    font-weight: 500;
    color: #2D3134;
}

@media screen and (max-width: 768px) {
    .N-about-us-section h1 {
        font-size: 24px;
        font-weight: 500;
    }
    .N-about-us-section {
        margin: 1.5rem auto;
    }
}

