.referral-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
   

}

.referral-container h1{
    text-align: center;
    font-size: 36px;
    font-weight: 500;
}
.count-earnings{
    display: flex;
    gap: 3rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 10px;
    padding: 1rem;
    width: 300px;
    text-align: center;
    justify-content: center;
}
.count-earnings .number{
    font-size: 24px;
}
.referral-code-section{
   
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 10px;
    padding: 1rem;
    width: 300px;
}

.referral-code-section p{
    font-size: 16px;
    font-weight: 500;
    
}
.referral-code-section .code{
    font-size: 18px;
    color: #838383;
}
.referral-code-section .copy-icon{
    width: 35px;
    height: 35px;
    color: #6B45BC;
    
   
}
.referral-details{
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 7rem;
}
.share-friends{
background: rgb(2,0,36);
background: linear-gradient(to right, #9e70ff 0%, #6b45bc 66%);
color: white;
width: 311px;
height: 49px;
border-radius: 10px

}

@media screen and (max-width:768px){
    .referral-details{
        flex-direction: column;
    }
    .referral-details {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      .referral-code-section {
        order: 2; /* Change the order of this section */
        margin-bottom: 10px; /* Add margin to create space between sections */
      }
      
      .referral-share {
        order: 1; /* Change the order of this section */
      }
      .count-earnings{
        border: none;
        box-shadow: none;
      }
}