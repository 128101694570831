.hero__container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: 30vh;
    row-gap: 2rem;  
    color: #FFF;
}
.video-bg{
    opacity: 0.9;
    width: 100%;
    height: 80vh;
    object-fit: cover;
    background-color: #000;
    transition: transform 0.5s ease;

}
.video-bg video {
    width: 100%;
    height: 100%;
}
.video-bg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
} 
.sentence__container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 70%;
    max-width: 54rem;
    height: 4rem;
}
.sentence__container p {
    font-family: 'poppins';
    font-weight: 300;
    font-size: 2em;
    color: #FFF;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main__search_container {
    width: 100%;
    max-height: 60px;
}

.search__container {
    display: flex;
    align-items: center;
    padding: 15px 15px;
    gap: 20px;
    margin: 0 auto;
    margin-bottom: 0.1rem;
    width: 70%;
    max-width: 54rem;
    background: #FFFFFF;
    border: 0.5px solid #DEDEDE;
    box-shadow: 0px 0px 28px rgba(59, 59, 59, 0.12);
    border-radius: 100px;
    max-height: 3.5rem;
}

.search_result__container {
    color: #000;
    background-color: white;
    width: 60%;
    margin: 0 auto;
    border-radius: 1rem;
    margin-top: 0.1rem;

}
.search_result__container ul li {
    padding: 0.4rem 1rem;
    
}
.search_result__container ul li a {
    color: black;
}

.search-bar {
    color: #000;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* row-gap: 5px; */
}

.search-bar input {
    outline: none;
    border: none;
    width: 100%;
    font-size: 14px;
    color: #717171;
}
.search-bar input:active,
.search-bar input:focus {
    color: #000000;
}
.search-bar input:active #searchLabel,
.search-bar input:focus #searchLabel {
    font-size: 1rem;
}

.search__container img {
    height: 1.5rem;
    width: auto;
}

.search-bar img {
    height: 1.5rem;
    width: auto;
}

.location-dialog {
    width: 150px;
    color: black;
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    padding: 0 5px 0 10px;
    position: relative;
}

.selected-location-div {
    width: 100%;
    height: 100%;
    line-height: 40px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.location-dialog-box {
    position: absolute;
    top: 55px;
    right: 0;
    width: 180px;
    height: 200px;
    overflow: scroll;
    background-color: white;
    border-radius: 7px;
    display: none;
    opacity: 0;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    transition: all 1s ease-in-out;

}

.location-dialog-box::-webkit-scrollbar {
    width: 5px;
}

.l-d-active {
    display: block !important;
    opacity: 1 !important;
    z-index: 1000;
}

.location-dialog-box p {
    font-size: 16px;
    padding: 10px;
    margin: 0 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.b-g-h-image {
    width: 100%; 
    height: auto; 
    opacity: 1;
    transition: opacity .8s ease-in-out; 
  }
  
  .b-g-h-image.b-g-h-hidden {
    opacity: 0; 
  }
@media screen and (max-width:820px) {
    .sentence__container,
    .search__container {
        width: 85%;
    }
}

@media screen and (min-width:820px) {
    .sentence__container p {
        font-size: 2.5em;
    }
}


@media screen and (max-width:630px) {
      .search-bar{
        font-size: 12px;
    }
    .search__container {
        width: 90%;
    }
    .video-hide {
        display: none;
    }
    .video-bg {
        background: white;
        opacity: 1;
        height: 22rem;
    }
    .video-bg img{
        visibility: hidden;
    }

    .sentence__container p {
        color: #000;
        font-size: 19px;
        font-weight: 400;
    }
    .hero__container {
        top: 8.1875rem;
        row-gap: 1em;
    }
    .search__container img {
        height: 1.5rem;
    }
     .location-dialog {
        /* width: 120px; */
        padding: 0 3px 0 7px;
        font-size: 12px;
    }
    .selected-location-div svg{
        height: 15px;
    }
}

@media screen and (max-width:478px) {
    .search__container,
    .sentence__container {
        width: 89%;
    }
}