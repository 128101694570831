.lc-main-container {
    width: 100%;
    max-width: 470px;
    border-radius: 15px;
    border: 1px solid rgb(187, 187, 187);
    height: auto;
    transition: all 0.2s ease-in-out;
}

.lc-main-container:hover {
    box-shadow: 0 0 10px 0 rgb(187, 187, 187);
}


@keyframes enter-animation {

    0% {
        transform: translateY(80px);
        opacity: 0;
        scale: 0.95;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
        scale: 1;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .image-animation {
        animation: enter-animation 0.6s 1;
    }
}


.lc-image-container {
    width: 100%;
    height: 200px;
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    position: relative;
}

.lc-image-container .lc-image {
    width: 100%;
    height: 100%;
    background-color: rgb(187, 187, 187);
}

.lc-image-container .lc-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.lc-image-container .lc-image-premium {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #023760;
    color: #fff;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 6px;
}

.lc-image-container .lc-image-like {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.lc-image-container .lc-image-offer-tag {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #512DC8;
    color: #fff;
    font-size: 13px;
    padding: 5px 30px 5px 10px;
    border-radius: 0 40px 0 0;
}

.lc-info-container {
    height: 130px;
}

.lc-info-container .lc-info-slices {
    height: 100px;
    padding: 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.lc-info-slices .lc-basic-info-panel {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.lc-basic-info-name-addr {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    max-width: 80%;
}

.lc-basic-info-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.lc-basic-info-addr {
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    color: rgb(119, 119, 119);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.lc-basic-info-rating {
    display: flex;
    background-color: #023760;
    color: #fff;
    gap: 2px;
    padding: 4px 8px;
    border-radius: 12px;
    height: 24px;
    margin-bottom: 2px;
}

.lc-basic-info-rating-stars {
    height: 20px;
    line-height: 16px;
    font-size: 16px;

}

.lc-basic-info-rating-num {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
}

.lc-info-slices .lc-facilities-panel {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lc-facilities-section {
    height: 30px;
    display: flex;
    gap: 5px;
}

.lc-facilities-icon-box {
    height: 100%;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lc-facilities-icon-box img {
    width: 20px;
    height: 20px;
    aspect-ratio: 1;

}

.lc-offer-price-section {
    height: 30px;
    font-size: 13px;
    font-weight: 400;
    line-height: 30px;
    color: rgb(119, 119, 119);

}

.lc-info-container .lc-book-now-btn {
    height: 30px;
    width: 100%;
    padding: 2px 0;
    background-color: #F8F8F8;
    color: #171717;
    display: flex;
    border-radius: 0 0 15px 15px;
    align-items: center;

}

.lc-book-now-btn button {
    font-size: 14px;
    width: 50%;
    flex-shrink: 1;
    flex-grow: 1;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
}

.lc-book-now-btn button:nth-child(2) {
    border-left: 1px solid rgb(187, 187, 187);
}

@media screen and (max-width: 500px) {
    .lc-main-container {
        width: 100%;
        max-width: 350px;
        margin: auto;
        height: auto;
    }

    .lc-image-container {
        height: 170px;
    }
}